import FalconCloseButton from 'components/common/FalconCloseButton';
import { Modal } from 'react-bootstrap';
import NewsFormCopy from './Forms/NewsFormCopy';
import { useEffect, useMemo, useState } from 'react';
import FurnituresFixturesFormCopy from './Forms/FurnitureFixturesFormCopy';
import PortfolioFormCopy from './Forms/PortfolioFormCopy';

const ReSubmitModal = ({ show, handleClose, data }) => {
  const [formData, setFormData] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const createFileFromData = fileData => {
    const file = new File([fileData.uri], fileData.filename, {
      type: fileData.type,
      lastModified: new Date(fileData.last_modified_date).getTime()
    });

    return file;
  };

  const documents = useMemo(() => {
    return data?.category01ISbb_q4sg_content_categoriesID === '25666'
      ? [
          formData?.document02ISfile,
          formData?.document03ISfile,
          formData?.document04ISfile,
          formData?.document05ISfile,
          formData?.document06ISfile,
          formData?.document07ISfile,
          formData?.document08ISfile,
          formData?.document09ISfile
        ]
          .filter(el => el)
          .map(createFileFromData)
      : [
          formData?.document01ISfile,
          formData?.document02ISfile,
          formData?.document03ISfile,
          formData?.document04ISfile,
          formData?.document05ISfile,
          formData?.document06ISfile,
          formData?.document07ISfile,
          formData?.document08ISfile,
          formData?.document09ISfile
        ]
          .filter(el => el)
          .map(createFileFromData);
  }, [formData, data]);

  const images = useMemo(() => {
    return [
      formData?.image01ISfile,
      formData?.image02ISfile,
      formData?.image03ISfile,
      formData?.image04ISfile,
      formData?.image05ISfile,
      formData?.image06ISfile,
      formData?.image07ISfile,
      formData?.image08ISfile,
      formData?.image09ISfile
    ]
      .filter(el => el)
      .map(createFileFromData);
  }, [formData]);

  const renders = useMemo(() => {
    return [
      formData?.render01ISfile,
      formData?.render02ISfile,
      formData?.render03ISfile,
      formData?.render04ISfile,
      formData?.render05ISfile,
      formData?.render06ISfile,
      formData?.render07ISfile,
      formData?.render08ISfile,
      formData?.render09ISfile
    ]
      .filter(el => el)
      .map(createFileFromData);
  }, [formData]);

  const floorPlan = [formData?.document01ISfile]
    ?.filter(el => el)
    ?.map(createFileFromData);

  useEffect(() => {
    if (data) {
      if (data?.urlISplaintextbox) {
        setIsChecked(true);
      }
      setFormData({
        name: data?.name,
        category01ISbb_q4sg_content_categoriesID:
          data?.category01ISbb_q4sg_content_categoriesID,
        category02ISbb_q4sg_content_categoriesID:
          data?.category02ISbb_q4sg_content_categoriesID,
        category03ISbb_q4sg_content_categoriesID:
          data?.category03ISbb_q4sg_content_categoriesID,
        content01ISbb_q4sg_contentID: data?.content01ISbb_q4sg_contentID,
        summaryISsmallplaintextbox: data?.summaryISsmallplaintextbox,

        image01ISfile: data?.image01ISfile,
        image02ISfile: data?.image02ISfile,
        image03ISfile: data?.image03ISfile,
        image04ISfile: data?.image04ISfile,
        image05ISfile: data?.image05ISfile,
        image06ISfile: data?.image06ISfile,
        image07ISfile: data?.image07ISfile,
        image08ISfile: data?.image08ISfile,
        image09ISfile: data?.image09ISfile,

        document01ISfile: data?.document01ISfile,
        document02ISfile: data?.document02ISfile,
        document03ISfile: data?.document03ISfile,
        document04ISfile: data?.document04ISfile,
        document05ISfile: data?.document05ISfile,
        document06ISfile: data?.document06ISfile,
        document07ISfile: data?.document07ISfile,
        document08ISfile: data?.document08ISfile,
        document09ISfile: data?.document09ISfile,

        render01ISfile: data?.render01ISfile,
        render02ISfile: data?.render02ISfile,
        render03ISfile: data?.render03ISfile,
        render04ISfile: data?.render04ISfile,
        render05ISfile: data?.render05ISfile,
        render06ISfile: data?.render06ISfile,
        render07ISfile: data?.render07ISfile,
        render08ISfile: data?.render08ISfile,
        render09ISfile: data?.render09ISfile,

        materials: data?.materials,
        dimensions_net: data?.dimensions_net,
        urlISplaintextbox: data?.urlISplaintextbox
          ? data?.urlISplaintextbox
          : null
      });
    }
  }, [data]);
  console.log(data);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="border-0"
      centered
      size="lg"
    >
      <Modal.Header className="bg-modal-header light border-bottom-0">
        <FalconCloseButton noOutline onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <fieldset className={'form-legend-wrapper pb-6 px-4'}>
          <legend className="item-title fs-1 fw-semi-bold">
            Re Submit Content
          </legend>
          {formData && (
            <>
              {data?.category03ISbb_q4sg_content_categoriesID ? (
                <PortfolioFormCopy
                  formData={formData}
                  close={handleClose}
                  setFormData={setFormData}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  img={images}
                  doc={documents}
                  rdr={renders}
                  fp={floorPlan}
                  content={data}
                />
              ) : data?.category01ISbb_q4sg_content_categoriesID === '22278' ? (
                <NewsFormCopy
                  content={data}
                  formData={formData}
                  close={handleClose}
                  setFormData={setFormData}
                  img={images}
                />
              ) : (
                <FurnituresFixturesFormCopy
                  content={data}
                  formData={formData}
                  close={handleClose}
                  setFormData={setFormData}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  img={images}
                  doc={documents}
                />
              )}
            </>
          )}
        </fieldset>
      </Modal.Body>
    </Modal>
  );
};

export default ReSubmitModal;
