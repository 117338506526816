import React, { useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PageTitle from 'components/common/PageTitle';
import Flex from 'components/common/Flex';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import FalconImageModal from 'components/common/FalconImageModal';
import FalconImageViewButton from 'components/common/FalconImageViewButton';
import createMarkup from 'helpers/createMarkup';

const ProductInfo = ({ info, toBottom }) => {
  const { t } = useTranslation();
  const {
    title,
    subTitle,
    description,
    images,
    dimensions,
    materials,
    end_of_life
  } = info || {};

  const [isShowModal, setIsShowModal] = useState(false);
  const [currentSrc, setCurrentSrc] = useState(null);
  const refImg = useRef(null);

  const imageItems = images
    ? images.map(item => {
        const newItem = {
          ...item,
          original: item.src,
          thumbnail: item.src
        };
        return newItem;
      })
    : [];

  const onViewImageModal = () => {
    const index = refImg.current.getCurrentIndex();
    setCurrentSrc(imageItems[index]?.original);
    setIsShowModal(true);
  };

  const renderCustomControls = () => {
    return <FalconImageViewButton onClick={onViewImageModal} />;
  };

  const handleClose = () => {
    setIsShowModal(false);
  };
  return (
    <div>
      <div className="text-center fw-semi-bold">{t(subTitle)}</div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        <div className="mb-6">
          <Row>
            <Col lg={7} className="pt-2">
              {imageItems.length > 0 && (
                <ImageGallery
                  ref={refImg}
                  items={imageItems}
                  showFullscreenButton={false}
                  renderCustomControls={renderCustomControls}
                />
              )}
            </Col>
            <Col lg={5} className="">
              <fieldset
                className={
                  'form-legend-wrapper p-4 cursor-pointer border portfolio'
                }
              >
                <legend className="item-title fs-1 fw-bold hover-overlay-effect">
                  {t('Info')}
                </legend>
                <div
                  className="product-description mb-3"
                  dangerouslySetInnerHTML={createMarkup(description)}
                />
                <div className="product-dimensions mb-3">
                  {/* <div className="fs-1 fw-bold mb-2">{t('Dimensions')}</div> */}
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className=""
                  >
                    <div>
                      <div className="fs-1 fw-bold mb-1">
                        {t('Unit Dimensions')}
                      </div>
                      <div className="">{t(dimensions?.net)}</div>
                    </div>

                    {/* <div>
                      <div className="fs-1 fw-bold mb-1">{t('Packaged')}</div>
                      <div className="">{t(dimensions.packaged)}</div>
                    </div>

                    <div>
                      <div className="fs-1 fw-bold mb-1">{t('Pallet')}</div>
                      <div className="">{t(dimensions.pallet)}</div>
                    </div> */}
                  </Flex>
                </div>
                <div className="product-materials mb-3">
                  <div className="fs-1 fw-bold mb-2">{t('Materials')}</div>
                  <div
                    className=""
                    dangerouslySetInnerHTML={createMarkup(materials)}
                  />
                </div>
                <div className="product-dimensions mb-3">
                  <div className="fs-1 fw-bold mb-2">{t('End of Life')}</div>
                  <div
                    className=""
                    dangerouslySetInnerHTML={createMarkup(end_of_life)}
                  />
                </div>
                <div className="link fw-semi-bold mt-2 mb-4" onClick={toBottom}>
                  {t('Scroll to bottom for documents')}
                </div>
              </fieldset>
            </Col>
          </Row>
        </div>
      </div>
      <FalconImageModal
        show={isShowModal}
        src={currentSrc}
        handleClose={handleClose}
      />
    </div>
  );
};

ProductInfo.propTypes = {
  info: PropTypes.object,
  toBottom: PropTypes.func
};

export default ProductInfo;
