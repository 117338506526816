import { useSelector } from 'react-redux';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { useEffect, useMemo, useState } from 'react';
import CardDropdown from 'components/common/CardDropdown';

import { useTranslation } from 'react-i18next';
import Actions from './actions/Action';
import Rejected from './actions/Rejected';
import Approving from './actions/Approving';
import { useGetApprovingContentMutation } from 'utils/rest/content/getAdmingContent';
import Loading from 'components/loading';
import RejectedModal from './actions/RejectedModal';

const Approval = ({ loading }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [contentRejectedData, setContentRejectedData] = useState();
  const { approvingContent } = useSelector(state => state.content);
  const { t } = useTranslation();

  const { mutate: getApprovingContent, isPending } =
    useGetApprovingContentMutation();

  const ActionButtons = ({ data, content }) => {
    return (
      <CardDropdown iconClassName="fs--1">
        <div className="py-2">
          {data === 'rejected' ? (
            <Rejected content={content} />
          ) : data === 'approving' ? (
            <Approving
              content={content}
              loading={loading}
              openModal={setIsOpenModal}
              setContent={setContentRejectedData}
            />
          ) : (
            <Actions />
          )}
        </div>
      </CardDropdown>
    );
  };

  const columns = useMemo(() => {
    let result = [];
    result = [
      {
        accessor: 'name',
        Header: t('Name')
      },
      {
        accessor: 'date_added',
        Header: t('dateUploaded')
      },
      {
        accessor: 'status',
        Header: t('Status'),
        Cell: rowData => {
          return rowData.row.original.status;
        }
      },
      {
        accessor: 'action',
        Header: t('Action'),
        Cell: rowData => {
          const data = rowData.row.original;

          if (rowData.row.original.status.includes('In Review'))
            return (
              <div className="text-center">
                <ActionButtons data={'approving'} content={data} />
              </div>
            );

        }
      }
    ];
    return result;
  }, [approvingContent]);

  const contentData = useMemo(() => {
    let results = [];

    results = approvingContent
      ?.map(c => {
        const date = c._dateadded.split('T')[0].replace(/-/g, '/');
        const time = c._dateadded.split('T')[1].split('+')[0];

        let status;
        if (!c.preview_importedYN) {
          status = 'Processing';
        } else if (c.preview_importedYN) {
          status = 'In Review';
        }
        if (c.approvedYN) {
          status = 'Approved';
        } else if (c.rejectedYN) {
          status = 'Rejected';
        }

        return {
          content: c,
          _id: c._id,
          name: c.name,
          date_added: `${date} ${time}`,
          status: status
        };
      })
      .sort((a, b) => {
        const order = {
          'In Review': 1,
          Processing: 2,
          Approved: 3,
          Rejected: 3
        };

        return order[a.status] - order[b.status];
      });

    return results;
  }, [approvingContent]);

  useEffect(() => {
    getApprovingContent();
  }, []);

  if (isPending)
    return (
      <Loading style={{ marginTop: 0, height: '100%' }} className="d-flex" />
    );

  return (
    <>
      <div>
        {(!approvingContent || approvingContent.length === 0) && (
          <div className="d-flex justify-content-center content-border">
            <div className="d-flex flex-column gap-2">
              <h5>Content Awaiting Approval</h5>
              <div className="d-flex justify-content-center  content-border">
                <span className="fs-18">
                  There is no content waiting to be approved
                </span>
              </div>
            </div>
          </div>
        )}
        {approvingContent && approvingContent.length > 0 && (
          <div className="extended-table">
            <AdvanceTableWrapper
              columns={columns}
              data={contentData}
              sortable
              pagination
              perPage={10}
            >
              <AdvanceTable
                table
                headerClassName="bg-200 text-700 text-nowrap align-middle fs-0"
                rowClassName="align-middle white-space-nowrap fs-0"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
              />

              <div className="mt-3">
                <AdvanceTablePagination table />
              </div>
            </AdvanceTableWrapper>
          </div>
        )}
      </div>
      {isOpenModal && (
        <RejectedModal
          show={isOpenModal}
          handleClose={setIsOpenModal}
          content={contentRejectedData}
          loading={loading}
        />
      )}
    </>
  );
};

export default Approval;
