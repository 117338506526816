import { Button, Form, Row } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { useEffect, useState } from 'react';
import DropFiles from '../content/DropFiles';
import ToastifyNotification from 'utils/toast/ToastifyNotification';
import { useUploadContentMutation } from 'utils/rest/content/uploadContent';
import { filesBase64 } from 'utils/files/filesToBase64';
import { useSelector } from 'react-redux';
import { useGetContentMutation } from 'utils/rest/content/getContent';

const NewsForm = ({ close, prev }) => {
  const [images, setImages] = useState([]);
  const [dragging, setDragging] = useState(false);

  const { user } = useSelector(state => state.auth);
  const appsetting = useSelector(state => state.appsetting);
  const setCategory = appsetting?.category_tree?.find(cat =>
    cat.name.toLowerCase().includes('news')
  );

  const [formData, setFormData] = useState({
    name: '',
    category01ISbb_q4sg_content_categoriesID: setCategory._id,
    summaryISsmallplaintextbox: ''
  });

  const { mutate: uploadContent } = useUploadContentMutation();
  const { mutate: getContent } = useGetContentMutation();

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleBack = () => {
    prev();
    close();
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const dataImg = await filesBase64(formData, images, 'img');

    if (dataImg) {
      uploadContent(dataImg, {
        onSuccess: () => {
          close();
          getContent(user?._id);
        }
      });
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (type === 'img') {
      setImages(prev => [...prev, ...droppedFiles]);
    } else {
      setDocuments(prev => [...prev, ...droppedFiles]);
    }
  };

  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type === 'img') {
      setDragging({ images: true, documents: false });
    } else {
      setDragging({ images: false, documents: true });
    }
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  useEffect(() => {
    const acceptedImgsFormat = ['image'];

    const checkImagesFile = images.filter(
      el =>
        acceptedImgsFormat.includes(el.type.split('/')[0]) ||
        acceptedImgsFormat.includes(el.type.split('/')[1])
    );

    if (images.length !== checkImagesFile.length) {
      ToastifyNotification({
        message: 'You can drop only "images" formats for Image',
        errorMessage: true
      });
      setImages(checkImagesFile);
    } else if (images && images.length != 0 && images?.length > 1) {
      ToastifyNotification({
        message: 'You can drop only 1 image same time',
        errorMessage: true
      });
      const newImages = images.slice(0, 1);
      setImages(newImages);
    } else {
      setImages(images);
    }
  }, [images]);

  return (
    <Form onSubmit={handleSubmit} className="px-6 pt-4">
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Name</legend>
          <Form.Control
            value={formData.name}
            name="name"
            onChange={handleChange}
            required
          />
        </fieldset>
      </Row>
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Information</legend>
          <Form.Control
            value={formData.summaryISsmallplaintextbox}
            name="summaryISsmallplaintextbox"
            onChange={handleChange}
            required
          />
        </fieldset>
      </Row>
      <Row>
        <fieldset
          className="form-legend-item-wrapper mb-4 position-relative"
          style={{ zIndex: 50 }}
        >
          <legend className="item-title fs-1 d-flex align-items-center">
            <span className="cursor-pointer fw-semi-bold">Image</span>
          </legend>
          <label
            style={{
              minHeight: '120px',
              maxHeight: '120px',
              width: '100%'
            }}
            onDragOver={e => handleDragOver(e, 'img')}
            onDragLeave={() => handleDragLeave('img')}
            onDrop={e => handleDrop(e, 'img')}
          >
            <DropFiles
              dragging={dragging}
              items={images}
              setFiles={setImages}
              form={formData}
              setForm={setFormData}
              type={'news'}
            />
          </label>
        </fieldset>
      </Row>
      <Flex className="gap-5">
        <Button variant="primary" type="submit">
          Submit For Approval
        </Button>
        <Button variant="primary" type="button" onClick={handleBack}>
          Back
        </Button>
      </Flex>
    </Form>
  );
};

export default NewsForm;
