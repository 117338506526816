import React, { Fragment, useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import ProductContent from './ProductContent';
import { useLocation } from 'react-router-dom';
import { replaceDashesWithSpaces } from 'helpers/utils';

const ProductTabWrapper = ({ categories, setContentId }) => {
  // const { t } = useTranslation();
  const { search } = useLocation();
  const [selectedKey, setSelectedKey] = useState(categories[0]._id);

  useEffect(() => {
    let searchParams = new URLSearchParams(search);
    // console.log(searchParams.get('tab'),'searchParams.get')
    if (searchParams.get('tab')) {
      // Get the value of the parameter from the URLSearchParams object
      const tabName = replaceDashesWithSpaces(searchParams.get('tab'));
      const categoryIndex = categories.findIndex(
        category =>
          category.name === tabName ||
          (category?.sub_branches?.length &&
            category?.sub_branches[0]?.name === tabName)
      );
      categoryIndex !== -1
        ? setSelectedKey(categories[categoryIndex]?._id)
        : setSelectedKey(categories[0]?._id);
    }
  }, [categories, search]);

  return (
    <Tab.Container
      activeKey={selectedKey}
      onSelect={k => setSelectedKey(k)}
      id="countries-tab"
      transition={true}
    >
      <Flex justifyContent="center" alignItems="center" className="">
        <Nav variant="tabs" className="flex-row">
          {categories.length > 1 &&
            categories.map((c, index) => (
              <Fragment key={index}>
                <Nav.Item>
                  <Nav.Link eventKey={c._id} className="fw-semi-bold fs-1">
                    {c.name}
                  </Nav.Link>
                </Nav.Item>
              </Fragment>
            ))}
        </Nav>
      </Flex>
      <Tab.Content>
        {categories.map((c, index) => (
          <Fragment key={index}>
            <Tab.Pane eventKey={c._id} title={c.name} className="">
              {c.content_list && c.content_list.length > 0 ? (
                <ProductTabWrapper
                  categories={c.content_list}
                  setContentId={setContentId}
                />
              ) : (
                <ProductContent info={c} setContentId={setContentId} />
              )}
            </Tab.Pane>
          </Fragment>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

ProductTabWrapper.propTypes = {
  categories: PropTypes.array
};

export default ProductTabWrapper;
