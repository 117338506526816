import { Fragment, useEffect, useMemo, useState } from 'react';
import Loading from 'components/loading/index';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Nav, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Uploaded from './Uploaded';
import Approval from './Approval';

const MyContent = () => {
  const [kind, setKind] = useState('uploaded');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const kinds = [
    {
      key: 'uploaded',
      name: t('uploaded')
    },
    {
      key: 'approval',
      name: t('approval')
    }
  ];

  const { user } = useSelector(state => state.auth);

  if (loading)
    return (
      <Loading style={{ marginTop: 0, height: '100%' }} className="d-flex" />
    );

  return (
    <Tab.Container
      activeKey={kind}
      onSelect={k => setKind(k)}
      id="countries-tab"
      transition={false}
    >
      {user.user_type === 'Admin' ? (
        <>
          <Flex justifyContent="between" alignItems="center" className="">
            <Nav variant="tabs" className="flex-row mb-3">
              {kinds.map((c, index) => (
                <Fragment key={index}>
                  <Nav.Item>
                    <Nav.Link eventKey={c.key} className="fw-semi-bold fs-1">
                      {t(c.name)}
                    </Nav.Link>
                  </Nav.Item>
                </Fragment>
              ))}
            </Nav>
          </Flex>
          <Tab.Content>
            {kind === 'uploaded' && <Uploaded />}
            {kind === 'approval' && <Approval loading={setLoading} />}
          </Tab.Content>
        </>
      ) : (
        <Tab.Content>
          <Uploaded />
        </Tab.Content>
      )}
    </Tab.Container>
  );
};
export default MyContent;
