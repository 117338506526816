import { useMutation } from '@tanstack/react-query';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';

async function postTracking(body) {
  const { data } = await https.post(ENDPOINTS.TRACKING, body);
  return data;
}

export const usePostTrackingMutation = () => {
  return useMutation({
    mutationFn: data => postTracking(data),
    onSuccess: () => {
      console.log('SUCCESSFULY');
    },
    onError: error => {
      console.log(error);
    }
  });
};
