import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation } from 'react-i18next';
import { getIcon, getFileType } from 'helpers/utils';
import { setError } from 'redux/slicers/appsettingSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { getErrorMesssage } from 'services/axiosHelpers';
import Loading from 'components/loading';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const PlanViewercomponent = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);

  const ref = useRef(null);
  const dispatch = useDispatch();
  const _isMounted = useRef(false);
  const title = 'Store Plan';
  const { t } = useTranslation();

  const onDocumentLoadSuccess = numPages => {
    setNumPages(numPages._pdfInfo.numPages);
  };

  const goToPrevPage = () => {
    setLoading(true);
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(numPages);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const goToNextPage = () => {
    setLoading(true);
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    } else {
      setPageNumber(1);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const onPrint = () => {
    //
  };

  const onDownload = () => {
    //
  };

  const handleOpenPdf = () => {
    console.log(file);
  };

  const getPDF = async url => {
    try {
      const ep = `${endpoint.getPDF(url)}`;
      const res = await axios.get(ep);
      const infoData = res.data;

      if (infoData?.base64) {
        // const data = infoData?.base64.replace(
        //   /^data:application\/pdf;base64,/,
        //   ''
        // );
        const data = infoData?.base64;
        setPdf(data);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    }
  };
  useEffect(() => {
    if (file && file.uri && getFileType(file.type) === 'pdf') {
      let url = file.uri;

      url = url.split('.pdf', 1) + '.pdf';

      getPDF(url);
    }
  }, [file]);

  return (
    <fieldset
      className={'form-legend-wrapper p-4 cursor-pointer border plan-viewer'}
    >
      <legend className="item-title fs-1 fw-semi-bold hover-overlay-effect">
        {t(title)}
      </legend>
      <div
        className="plan-image"
        style={{ position: 'relative', minHeight: 500 }}
      >
        {getFileType(file.type) === 'image' && (
          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut }) => (
              <React.Fragment>
                <div className="plan-viewer-controlls">
                  <div
                    className="plan-viewer-controll-btn cursor-pointer mb-3 hover-focus-effect"
                    onClick={() => zoomIn()}
                  >
                    <span className="icon">
                      {getIcon({
                        icon_component: 'bs/BsPlusLg',
                        icon_component_attributes: {
                          fontSize: 30,
                          marginRight: 0
                        }
                      })}
                    </span>
                  </div>
                  <div
                    className="plan-viewer-controll-btn cursor-pointer mb-3 hover-focus-effect"
                    onClick={() => zoomOut()}
                  >
                    <span className="icon">
                      {getIcon({
                        icon_component: 'bs/BsDashLg',
                        icon_component_attributes: {
                          fontSize: 30,
                          marginRight: 0
                        }
                      })}
                    </span>
                  </div>
                  <div
                    className="plan-viewer-controll-btn cursor-pointer mb-3 hover-focus-effect"
                    onClick={onPrint}
                  >
                    <span className="icon">
                      {getIcon({
                        icon_component: 'bs/BsPrinter',
                        icon_component_attributes: {
                          fontSize: 30,
                          marginRight: 0
                        }
                      })}
                    </span>
                  </div>
                  <div
                    className="plan-viewer-controll-btn cursor-pointer hover-focus-effect"
                    onClick={onDownload}
                  >
                    <span className="icon">
                      {getIcon({
                        icon_component: 'bs/BsDownload',
                        icon_component_attributes: {
                          fontSize: 30,
                          marginRight: 0
                        }
                      })}
                    </span>
                  </div>
                </div>
                <TransformComponent>
                  <Flex justifyContent="center" alignItems={'center'}>
                    <Image fluid src={file.uri} alt="" />
                  </Flex>
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        )}
        {getFileType(file.type) === 'pdf' && (
          <>
            {loading ? (
              <Card.Body className="height-800">
                <Loading style={{ marginTop: 50 }} />
              </Card.Body>
            ) : (
              <div className="plan-wrap">
                <a href={file.uri} target="_blank">
                  <Document
                    file={pdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoad={() => console.log('here')}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </a>

                <button
                  onClick={goToPrevPage}
                  className="image-gallery-icon image-gallery-left-nav"
                >
                  <svg
                    class="image-gallery-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="6 0 12 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                </button>
                <button
                  className="image-gallery-icon image-gallery-right-nav"
                  onClick={goToNextPage}
                >
                  <svg
                    class="image-gallery-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="6 0 12 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </fieldset>
  );
};

PlanViewercomponent.propTypes = {
  file: PropTypes.object
};

export default PlanViewercomponent;
