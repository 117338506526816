import { Dropdown } from 'react-bootstrap';
import { getIcon } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

const Actions = () => {
  const { t } = useTranslation();
  return (
    <>
      <Dropdown.Item
        onClick={e => {
          e.preventDefault(e);
        }}
      >
        <span>
          {getIcon({
            icon_component: 'ri/RiEditBoxFill',
            icon_component_attributes: { fontSize: 16, marginRight: 10 }
          })}
        </span>
        <span>{t('Edit')}</span>
      </Dropdown.Item>
      <Dropdown.Item
        onClick={e => {
          e.preventDefault(e);
        }}
      >
        <span>
          {getIcon({
            icon_component: 'md/MdDelete',
            icon_component_attributes: { fontSize: 16, marginRight: 10 }
          })}
        </span>
        <span>{t('Delete')}</span>
      </Dropdown.Item>
    </>
  );
};

export default Actions;
