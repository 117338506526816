import React from 'react';
import { useSelector } from 'react-redux';
import Flex from 'components/common/Flex';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PortfolioFilter = ({
  portfolioFilter,
  portfolioByCountry,
  isShowPortfolioItems,
  setIsShowPortfolioItems,
  onGoItem,
  portfoliosCountryFiltered,
  setPortfoliosCountryFiltered,
  setPortfoliosFilteredEnglish
}) => {
  const { languageCode } = useSelector(state => state.subCategories);

  const { t } = useTranslation();
  return (
    <>
      {!isShowPortfolioItems && (
        <Flex
          justifyContent="start"
          alignItems="center"
          className="ms-3"
          wrap="wrap"
        >
          {portfolioFilter &&
            portfolioFilter?.map((filter, i) => (
              <div
                className="slide-wrapper hover-overlay-effect"
                onClick={() => {
                  const filterWord = filter.summaryISsmallplaintextbox;
                  const filteredItems =
                    filterWord === 'all'
                      ? portfolioByCountry
                      : portfolioByCountry?.filter(item =>
                          item.name.toLowerCase().includes(filterWord)
                        );
                  setPortfoliosCountryFiltered(filteredItems);
                  languageCode === 'GB' &&
                    setPortfoliosFilteredEnglish(filteredItems);
                  setIsShowPortfolioItems(true);
                }}
                key={i}
              >
                <div className="image-wrapper m-2">
                  <Image
                    fluid
                    src={filter['image01ISfile'] ?? placeholderImage}
                    className="portfolio-image"
                  />
                </div>
                <div className="">
                  <div className="text-center mt-2 fw-semi-bold fs-1">
                    {t(filter['name'])}
                  </div>
                </div>
              </div>
            ))}
        </Flex>
      )}
      {isShowPortfolioItems && (
        <div className="d-flex flex-column">
          <div
            className="d-flex align-items-center"
            onClick={() => {
              setIsShowPortfolioItems(false);
              setPortfoliosCountryFiltered([]);
            }}
          >
            <span>{t('Go Back')}</span>
          </div>

          {portfoliosCountryFiltered.length ? (
            <Flex
              justifyContent="start"
              alignItems="center"
              className="ms-3"
              wrap="wrap"
            >
              {portfoliosCountryFiltered.map((slide, i) => (
                <div
                  className="slide-wrapper hover-overlay-effect"
                  onClick={() => onGoItem(slide['_id'])}
                  key={i}
                >
                  <div className="image-wrapper m-2">
                    <Image
                      fluid
                      src={slide['image01ISfile'] ?? placeholderImage}
                      className="portfolio-image"
                    />
                  </div>
                  <div className="">
                    <div className="text-center mt-2 fw-semi-bold fs-1">
                      {t(slide['name'])}
                    </div>
                  </div>
                </div>
              ))}
            </Flex>
          ) : (
            <div className="d-flex justify-content-center w-100">
              <span className="fw-semi-bold">
                {t('No elements in this Portfolio')}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PortfolioFilter;
