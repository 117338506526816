import React, { useMemo } from 'react';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import createMarkup from 'helpers/createMarkup';
import truncate from 'truncate-html';
import 'moment/locale/it';
import 'moment/locale/de';

const NewsList = () => {
  const { translations } = useSelector(state => state.appsetting);
  const { languageCode } = useSelector(state => state.subCategories);
  moment.locale(languageCode === 'GB' ? 'en' : languageCode);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const maxLength = 350;

  const category_tree = useSelector(state => state.appsetting.category_tree);
  const catsFromState = category_tree?.find(
    c => c.name === 'News'
  ).sub_branches;

  const news = useMemo(() => {
    let result = [];
    catsFromState?.forEach(cat => {
      const newses = cat.content_list.map(n => {
        return {
          ...n,
          name:
            languageCode === 'GB'
              ? n.name
              : translations[n._id][languageCode].name,
          text:
            languageCode === 'GB'
              ? n.summaryISsmallplaintextbox
              : translations[n._id][languageCode].summaryISsmallplaintextbox,
          date: moment(n._dateadded).format('D MMMM YYYY'),
          time: moment(n._dateadded).format('h:mm:ss a')
        };
      });
      if (newses[0]) {
        result = [...result, newses[0]];
      }
      if (newses[1]) {
        result = [...result, newses[1]];
      }
    });

    return result;
  }, [category_tree, languageCode]);

  const title = 'News';
  const contentDiv = useMemo(() => {
    let divLength = 0;
    let result = '';
    for (let index = 0; index < news.length; index++) {
      const item = news[index];
      divLength = divLength + item.text.toString().length;
      let itemDiv = '';
      if (divLength < maxLength) {
        itemDiv = (
          <div key={index} className="news-item mb-4">
            <div className="news-text fw-semi-bold mt-2">{item.name}</div>
            <div className="news-date-time">
              <Flex alignItems="center" justifyContent="start">
                <div className="news-date fs--1 fw-semi-bold me-2">
                  {item.date}
                </div>
                <div className="news-time fs--1">{item.time}</div>
              </Flex>
            </div>
            <div
              className="news-text mt-2"
              dangerouslySetInnerHTML={createMarkup(item.text)}
            />
          </div>
        );
        result = (
          <>
            {result}
            {itemDiv}
          </>
        );
      } else {
        itemDiv = (
          <div key={index} className="news-item mb-4">
            <div className="news-text fw-semi-bold mt-2">{item.name}</div>
            <div className="news-date-time">
              <Flex alignItems="center" justifyContent="start">
                <div className="news-date fs--1 fw-semi-bold me-2">
                  {item.date}
                </div>
                <div className="news-time fs--1">{item.time}</div>
              </Flex>
            </div>
            <div
              className="news-text mt-2"
              dangerouslySetInnerHTML={createMarkup(
                truncate(
                  item.text,
                  item.text.toString().length - divLength + maxLength
                )
              )}
            />
          </div>
        );
        result = (
          <>
            {result}
            {itemDiv}
          </>
        );
        return result;
      }
    }

    return result;
  }, [news, languageCode]);

  const goToNews = () => {
    navigate('/news');
  };

  return (
    <fieldset className="nav-card cursor-pointer" onClick={goToNews}>
      <legend className="nav-card-title fs-1 fw-semi-bold" onClick={goToNews}>
        {t(title)}
      </legend>
      <div className="nav-card-inner">
        <div className="news-wrapper">{contentDiv}</div>
      </div>
    </fieldset>
  );
};

export default NewsList;
