import FalconCloseButton from 'components/common/FalconCloseButton';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FurtitureFixtures from './FurnitureFixtures';
import MainCategory from './MainCategory';
import { useSelector } from 'react-redux';

const AddContentCategoryModal = ({
  show,
  handleClose,
  openContentForm,
  formData,
  setFormData,
  setContentModal
}) => {
  const [category, setCategory] = useState('');
  const { user } = useSelector(state => state.auth);

  const { t } = useTranslation();

  const title = t('New Content');

  const handleChange = (e, type) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setContentModal(type);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    handleClose();
    openContentForm();
  };

  const handleTeamChange = e => {
    e.preventDefault();
    setCategory(e.target.value);
    setContentModal(e.target.value);
  };

  useEffect(() => {
    return () => {
      setFormData({ category01ISbb_q4sg_content_categoriesID: '' });
      setCategory('');
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="border-0"
      centered
      size="lg"
    >
      <Modal.Header className="bg-modal-header light border-bottom-0">
        <FalconCloseButton noOutline onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <fieldset className={'form-legend-wrapper pb-6 px-4'}>
          <p className="text-center">
            Please select below where you would like to add new content
          </p>
          <legend className="item-title fs-1 fw-semi-bold">{t(title)}</legend>
          <Form onSubmit={handleSubmit} className="px-6 pt-4">
            {user.user_type === 'Supplier' && (
              <FurtitureFixtures
                form={formData}
                action={e => handleChange(e, 'f_f')}
              />
            )}
            {(user.user_type === 'Team' || user.user_type === 'Admin') && (
              <MainCategory category={category} action={handleTeamChange} />
            )}
            {category === 'f_f' && (
              <FurtitureFixtures
                form={formData}
                action={e => handleChange(e, 'f_f')}
              />
            )}

            <div className="text-end">
              <Button variant="primary" type="submit">
                Load Content Form
              </Button>
            </div>
          </Form>
        </fieldset>
      </Modal.Body>
    </Modal>
  );
};

export default AddContentCategoryModal;
