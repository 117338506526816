import { Button, Col, Form, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useEffect, useState } from 'react';
import { filesBase64 } from 'utils/files/filesToBase64';
import DropFiles from '../content/DropFiles';
import ToastifyNotification from 'utils/toast/ToastifyNotification';
import { useSelector } from 'react-redux';
import FurtitureFixtures from '../categories/FurnitureFixtures';
import { useGetContentMutation } from 'utils/rest/content/getContent';
import { useUploadContentMutation } from 'utils/rest/content/uploadContent';

const PortfolioForm = ({ close, prev }) => {
  const [contentList, setContentList] = useState();
  const [images, setImages] = useState([]);
  const [renders, setRenders] = useState([]);
  const [floorPlan, setFloorPlan] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [dragging, setDragging] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    category01ISbb_q4sg_content_categoriesID: '',
    category02ISbb_q4sg_content_categoriesID: '',
    category03ISbb_q4sg_content_categoriesID: '',
    content01ISbb_q4sg_contentID: '',
    summaryISsmallplaintextbox: '',
    dimensions_net: '',
    materials: '',
    urlISplaintextbox: null
  });

  const { mutate: uploadContent, isPending: contentLoading } =
    useUploadContentMutation();
  const { mutate: getContent } = useGetContentMutation();

  const { user } = useSelector(state => state.auth);
  const appsetting = useSelector(state => state.appsetting);

  const findDashboard = appsetting?.category_tree?.find(dash =>
    dash.name.toLowerCase().includes('dashboard')
  );
  const findShops = findDashboard?.sub_branches.find(shop =>
    shop.name.toLowerCase().includes('shop')
  );
  const findPortfolio = findShops?.sub_branches.find(port =>
    port.name.toLowerCase().includes('portfolio')
  );

  const { sub_branches } = findPortfolio;
  const matchedStore = sub_branches?.find(
    store => store._id === formData.category01ISbb_q4sg_content_categoriesID
  );

  const categories = [
    {
      id: 9396,
      name: 'Store Furniture Information'
    },
    {
      id: 9222,
      name: 'Shop Fit Information'
    }
  ];

  const handleBack = () => {
    prev();
    close();
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!isChecked) {
      await setFormData({
        ...formData,
        ...(formData.urlISplaintextbox = null)
      });
    }

    if (formData.category02ISbb_q4sg_content_categoriesID === '9222') {
      await setFormData({
        ...formData,
        ...(formData.category03ISbb_q4sg_content_categoriesID = null)
      });
    }

    const dataImg = await filesBase64(formData, images, 'img');
    const dataRrd = await filesBase64(dataImg, renders, 'rdr');
    const dataFlr = await filesBase64(dataRrd, floorPlan, 'flr');
    const dataDocs = await filesBase64(dataFlr, documents, 'docs');

    await uploadContent(dataDocs, {
      onSuccess: () => {
        close();
        getContent(user?._id);
      }
    });
  };

  const handleDragOver = (e, type) => {
    e.preventDefault();

    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);

    if (type === 'img') {
      setImages(prev => [...prev, ...droppedFiles]);
    }
    if (type === 'rdr') {
      setRenders(prev => [...prev, ...droppedFiles]);
    }
    if (type === 'flr') {
      setFloorPlan(prev => [...prev, ...droppedFiles]);
    }
    if (type === 'doc') {
      setDocuments(prev => [...prev, ...droppedFiles]);
    }
  };

  useEffect(() => {
    if (matchedStore) {
      const { content_list } = matchedStore;
      setContentList(content_list);
    }
  }, [matchedStore, findPortfolio]);

  useEffect(() => {
    const acceptedImgsFormat = ['image', 'x-zip-compressed'];
    const acceptedFloorFormat = ['application/pdf'];
    const acceptedDocsFormat = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/x-zip-compressed'
    ];

    const checkImagesFile = images.filter(
      el =>
        acceptedImgsFormat.includes(el.type.split('/')[0]) ||
        acceptedImgsFormat.includes(el.type.split('/')[1])
    );

    if (images.length !== checkImagesFile.length) {
      ToastifyNotification({
        message: 'You can drop only "images" or .zip formats  for Images',
        errorMessage: true
      });
      setImages(checkImagesFile);
    } else if (images && images.length != 0 && images?.length > 9) {
      ToastifyNotification({
        message: 'You can drop only 9 images same time',
        errorMessage: true
      });
      const newImages = images.slice(0, 9);
      setImages(newImages);
    } else {
      setImages(images);
    }

    const checkRendersFile = renders?.filter(
      el =>
        acceptedImgsFormat.includes(el.type.split('/')[0]) ||
        acceptedImgsFormat.includes(el.type.split('/')[1])
    );

    if (renders.length !== checkRendersFile.length) {
      ToastifyNotification({
        message: 'You can drop only "images" or .zip formats for Renders',
        errorMessage: true
      });
      setRenders(checkRendersFile);
    } else if (renders && renders.length !== 0 && renders?.length > 9) {
      ToastifyNotification({
        message: 'You can drop only 9 images same time',
        errorMessage: true
      });
      const newRenders = renders?.slice(0, 9);
      setRenders(newRenders);
    } else {
      setRenders(renders);
    }

    const checkFloorFile = floorPlan?.filter(el =>
      acceptedFloorFormat.includes(el.type)
    );

    if (floorPlan.length !== checkFloorFile.length) {
      ToastifyNotification({
        message: 'You can drop only .pdf formats for Floorplan',
        errorMessage: true
      });
      setFloorPlan(checkFloorFile);
    } else if (floorPlan && floorPlan.length !== 0 && floorPlan?.length > 1) {
      ToastifyNotification({
        message: 'You can drop only 1 Floorplan same time',
        errorMessage: true
      });
      const newFloorPlan = floorPlan?.slice(0, 1);
      setFloorPlan(newFloorPlan);
    } else {
      setFloorPlan(floorPlan);
    }

    const checkDocsFile = documents.filter(el =>
      acceptedDocsFormat.includes(el.type)
    );

    if (documents.length !== checkDocsFile.length) {
      ToastifyNotification({
        message:
          'You can drop only .pdf, .docx, .xlsx or .zip formats for Documents',
        errorMessage: true
      });
      setDocuments(checkDocsFile);
    } else if (documents && documents.length != 0 && documents?.length > 8) {
      ToastifyNotification({
        message: 'You can drop only 8 documents same time',
        errorMessage: true
      });
      const newDocs = documents.slice(0, 8);
      setDocuments(newDocs);
    } else {
      setDocuments(documents);
    }
  }, [images, renders, floorPlan, documents]);

  return (
    <Form onSubmit={handleSubmit} className="px-6 pt-4">
      <div className="d-grid grid-cols-2 row">
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">
            Select Country
          </legend>
          <Form.Control
            as="select"
            className="form-select"
            value={formData.category01ISbb_q4sg_content_categoriesID}
            name="category01ISbb_q4sg_content_categoriesID"
            onChange={handleChange}
          >
            <option value="" disabled>
              Select
            </option>
            {sub_branches.map((country, idx) => (
              <option value={country._id} key={idx} onChange={handleChange}>
                {country.name}
              </option>
            ))}
          </Form.Control>
        </fieldset>

        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Select Store</legend>
          <Form.Control
            disabled={formData.category01ISbb_q4sg_content_categoriesID === ''}
            as="select"
            className="form-select"
            value={formData.content01ISbb_q4sg_contentID}
            name="content01ISbb_q4sg_contentID"
            onChange={handleChange}
          >
            <option value="" disabled>
              Select
            </option>
            {contentList && contentList.length > 0 ? (
              contentList?.map((content, idx) => (
                <option value={content._id} key={idx} onChange={handleChange}>
                  {content.name}
                </option>
              ))
            ) : (
              <option>No content list for this country</option>
            )}
          </Form.Control>
        </fieldset>
      </div>
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">
            Select Category
          </legend>
          <Form.Control
            disabled={formData.content01ISbb_q4sg_contentID === ''}
            as="select"
            className="form-select"
            value={formData.category02ISbb_q4sg_content_categoriesID}
            name="category02ISbb_q4sg_content_categoriesID"
            onChange={handleChange}
          >
            <option value="" disabled>
              Select
            </option>
            {categories.map((cat, idx) => (
              <option value={cat.id} key={idx} onChange={handleChange}>
                {cat.name}
              </option>
            ))}
          </Form.Control>
        </fieldset>
      </Row>
      <div className="portfolio-wrap">
        {formData.category02ISbb_q4sg_content_categoriesID == 9396 && (
          <FurtitureFixtures
            form={formData}
            action={handleChange}
            variant="portfolio"
          />
        )}
      </div>
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Name</legend>
          <Form.Control
            value={formData.name}
            name="name"
            onChange={handleChange}
            required
          />
        </fieldset>
      </Row>
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Information</legend>
          <Form.Control
            value={formData.summaryISsmallplaintextbox}
            name="summaryISsmallplaintextbox"
            onChange={handleChange}
            required
          />
        </fieldset>
      </Row>
      <Row>
        <fieldset
          className="form-legend-item-wrapper mb-4 position-relative"
          style={{ zIndex: 50 }}
        >
          <legend className="item-title fs-1 d-flex align-items-center">
            <span className="cursor-pointer fw-semi-bold">Images</span>
          </legend>
          <label
            style={{
              minHeight: '120px',
              maxHeight: '120px',
              width: '100%'
            }}
            onDragOver={e => handleDragOver(e, 'img')}
            onDragLeave={() => handleDragLeave('img')}
            onDrop={e => handleDrop(e, 'img')}
          >
            <DropFiles
              dragging={dragging}
              items={images}
              setFiles={setImages}
              form={formData}
              setForm={setFormData}
              type={'images'}
            />
          </label>
        </fieldset>
      </Row>
      <Row>
        <fieldset
          className="form-legend-item-wrapper mb-4 position-relative"
          style={{ zIndex: 50 }}
        >
          <legend className="item-title fs-1 d-flex align-items-center">
            <span className="cursor-pointer fw-semi-bold">Documents</span>
          </legend>
          <label
            style={{
              minHeight: '120px',
              maxHeight: '120px',
              width: '100%'
            }}
            onDragOver={e => handleDragOver(e, 'doc')}
            onDragLeave={() => handleDragLeave('doc')}
            onDrop={e => handleDrop(e, 'doc')}
          >
            <DropFiles
              dragging={dragging}
              items={documents}
              setFiles={setDocuments}
              form={formData}
              setForm={setFormData}
              type={'document'}
            />
          </label>
        </fieldset>
      </Row>
      <Row>
        <fieldset
          className="form-legend-item-wrapper mb-4 position-relative"
          style={{ zIndex: 50 }}
        >
          <legend className="item-title fs-1 d-flex align-items-center">
            <span className="cursor-pointer fw-semi-bold">Renders</span>
          </legend>
          <label
            style={{
              minHeight: '120px',
              maxHeight: '120px',
              width: '100%'
            }}
            onDragOver={e => handleDragOver(e, 'rdr')}
            onDragLeave={() => handleDragLeave('rdr')}
            onDrop={e => handleDrop(e, 'rdr')}
          >
            <DropFiles
              dragging={dragging}
              items={renders}
              setFiles={setRenders}
              form={formData}
              setForm={setFormData}
              type={'renders'}
            />
          </label>
        </fieldset>
      </Row>
      <Row>
        <fieldset
          className="form-legend-item-wrapper mb-4 position-relative"
          style={{ zIndex: 50 }}
        >
          <legend className="item-title fs-1 d-flex align-items-center">
            <span className="cursor-pointer fw-semi-bold">Floorplan</span>
          </legend>
          <label
            style={{
              minHeight: '120px',
              maxHeight: '120px',
              width: '100%'
            }}
            onDragOver={e => handleDragOver(e, 'flr')}
            onDragLeave={() => handleDragLeave('flr')}
            onDrop={e => handleDrop(e, 'flr')}
          >
            <DropFiles
              dragging={dragging}
              items={floorPlan}
              setFiles={setFloorPlan}
              form={formData}
              setForm={setFormData}
              type={'floor'}
            />
          </label>
        </fieldset>
      </Row>
      <Row>
        <Flex
          alignItems={'center'}
          className={`gap-2 ${isChecked ? 'mb-2' : 'mb-4'}`}
        >
          <label>Is this to replace an existing content?</label>
          <input
            className="mb-2"
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(prev => !prev)}
          />
        </Flex>
      </Row>
      {isChecked && (
        <Row>
          <fieldset className={'form-legend-item-wrapper mb-4'}>
            <legend className="item-title fs-1 fw-semi-bold">Link</legend>
            <Form.Control
              placeholder="Please provide the link to the current content"
              value={formData.urlISplaintextbox}
              name="urlISplaintextbox"
              onChange={handleChange}
              required
            />
          </fieldset>
        </Row>
      )}
      <Flex className="gap-5">
        <Button variant="primary" type="submit" disabled={contentLoading}>
          Submit For Approval
        </Button>
        <Button
          variant="primary"
          type="button"
          onClick={handleBack}
          disabled={contentLoading}
        >
          Back
        </Button>
      </Flex>
    </Form>
  );
};

export default PortfolioForm;
