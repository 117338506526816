import { Dropdown } from 'react-bootstrap';
import { getIcon } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { setError } from 'redux/slicers/appsettingSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import ReSubmitModal from './ResubmitModal';

const Rejected = ({ content }) => {
  const [newsFormOpen, setNewsFormOpen] = useState();
  const [formData, setFormData] = useState();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleAction = (e, type) => {
    e.stopPropagation();

    if (type === 'error') {
      dispatch(
        setError({
          isShow: true,
          title: 'See comments below',
          message: content?.rejected_reasonISsmallplaintextbox
        })
      );
    } else {
      setNewsFormOpen(true);
      setFormData(content);
    }
  };

  return (
    <>
      <>
        <Dropdown.Item
          as="button"
          type="button"
          onClick={e => handleAction(e, 'error')}
        >
          <span>
            {getIcon(
              {
                icon_component: 'fa6/FaCircleXmark',
                icon_component_attributes: { fontSize: 16 }
              },
              'red'
            )}
          </span>
          <span>{t('rejected')}</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={e => handleAction(e, 'resub')}>
          <span>
            {getIcon({
              icon_component: 'md/MdFileUpload',
              icon_component_attributes: { fontSize: 16, marginRight: 10 }
            })}
          </span>
          <span>{t('reSubmit')}</span>
        </Dropdown.Item>
      </>
      {newsFormOpen && (
        <ReSubmitModal
          data={formData}
          show={newsFormOpen}
          handleClose={() => setNewsFormOpen(false)}
        />
      )}
    </>
  );
};

export default Rejected;
