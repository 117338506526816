import { useMutation } from '@tanstack/react-query';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';
import ToastifyNotification from 'utils/toast/ToastifyNotification';

async function resubmitContent(values) {
  const { data } = await https.patch(
    `${ENDPOINTS.UPLOAD_CONTENT}/${values.id}`,
    values.body
  );
  return data;
}

export const useResubmitContentMutation = () => {
  return useMutation({
    mutationFn: id => resubmitContent(id),
    onSuccess: () => {
      ToastifyNotification({
        message: 'Your Content successfully uploaded',
        errorMessage: false
      });
    },
    onError: error => {
      console.log(error);
      if (error.response.data.error.includes('type')) {
        ToastifyNotification({
          message: error.response.data.error,
          errorMessage: true
        });
      } else
        ToastifyNotification({
          message: 'Successfully uploaded',
          errorMessage: true
        });
    }
  });
};
