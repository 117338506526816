import { useMutation } from '@tanstack/react-query';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';

async function reviewedContent(value) {
  try {
    const { data } = await https.patch(
      `${ENDPOINTS.UPLOAD_CONTENT}/${value.id}`,
      value.body
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}

export const useReviewedContentMutation = () => {
  return useMutation({
    mutationFn: value => reviewedContent(value)
  });
};
