import FalconCloseButton from 'components/common/FalconCloseButton';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetApprovingContentMutation } from 'utils/rest/content/getAdmingContent';
import { useGetContentMutation } from 'utils/rest/content/getContent';
import { useReviewedContentMutation } from 'utils/rest/content/patchReviewedContent';

const RejectedModal = ({ show, handleClose, content, loading }) => {
  const [formData, setFormData] = useState({
    rejected_reasonISsmallplaintextbox: '',
    rejectedYN: 1
  });

  const { user } = useSelector(state => state.auth);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const { mutate: reviewedContent, isPending } = useReviewedContentMutation();
  const { mutate: getContent } = useGetContentMutation();
  const { mutate: getApprovingContent } = useGetApprovingContentMutation();

  const handleSubmit = e => {
    e.preventDefault();
    loading(true);

    const data = { id: content.content._id, body: formData };
    reviewedContent(data, {
      onSuccess: data => {
        getContent(user?._id);
        getApprovingContent();
        ToastifyNotification({
          message: 'Rejecting done',
          errorMessage: false
        });
        loading(false);
      },
      onError: error => {
        console.log(error);
        loading(false);
      }
    });

    setTimeout(() => {
      loading(false);
    }, 1000);
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      contentClassName="border-0"
      centered
      size="lg"
    >
      <Modal.Header className="bg-modal-header light border-bottom-0">
        <FalconCloseButton noOutline onClick={() => handleClose(false)} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="px-3">
          <fieldset className={'form-legend-wrapper pb-6 px-4'}>
            <legend className="item-title fs-1 fw-semi-bold">
              Please enter a reason for rejection
            </legend>
            <Form.Control
              value={formData?.rejected_reasonISsmallplaintextbox}
              name="rejected_reasonISsmallplaintextbox"
              onChange={handleChange}
              required
            />
          </fieldset>
          <Button
            className="mt-4"
            variant="primary"
            type="submit"
            disabled={isPending}
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RejectedModal;
