import { Dropdown } from 'react-bootstrap';
import { getIcon } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { useReviewedContentMutation } from 'utils/rest/content/patchReviewedContent';
import ToastifyNotification from 'utils/toast/ToastifyNotification';
import { useGetContentMutation } from 'utils/rest/content/getContent';
import { useGetApprovingContentMutation } from 'utils/rest/content/getAdmingContent';
import { useSelector } from 'react-redux';

const Approving = ({ content, loading, openModal, setContent }) => {
  const { t } = useTranslation();

  const { user } = useSelector(state => state.auth);

  const { mutate: reviewedContent } = useReviewedContentMutation();
  const { mutate: getContent } = useGetContentMutation();
  const { mutate: getApprovingContent } = useGetApprovingContentMutation();

  const handleAction = (e, type) => {
    e.stopPropagation();

    if (type === 'approve') {
      loading(true);
      const data = { id: content.content._id, body: { approvedYN: 1 } };

      reviewedContent(data, {
        onSuccess: data => {
          getContent(user?._id);
          getApprovingContent();
          ToastifyNotification({
            message: 'Approving done',
            errorMessage: false
          });
          loading(false);
        },
        onError: error => {
          console.log(error);
          loading(false);
        }
      });

      setTimeout(() => {
        loading(false);
      }, 1000);
    } else {
      setContent(content);
      openModal(true);
    }
  };

  return (
    <>
      <Dropdown.Item
        as="button"
        type="button"
        onClick={e => handleAction(e, 'approve')}
      >
        <span>
          {getIcon(
            {
              icon_component: 'si/SiTicktick',
              icon_component_attributes: { fontSize: 16 }
            },
            'green'
          )}
        </span>
        <span>{t('approve')}</span>
      </Dropdown.Item>
      <Dropdown.Item onClick={e => handleAction(e, 'reject')}>
        <span>
          {getIcon(
            {
              icon_component: 'fa6/FaCircleXmark',
              icon_component_attributes: { fontSize: 16, marginRight: 10 }
            },
            'red'
          )}
        </span>
        <span>{t('reject')}</span>
      </Dropdown.Item>
    </>
  );
};

export default Approving;
