import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';
import { setApprovingContent } from 'redux/slicers/contentSlice';

async function getApprovingContent() {
  const { data } = await https.get(ENDPOINTS.GET_CONTENT);
  return data;
}

export const useGetApprovingContentMutation = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: () => getApprovingContent(),
    onSuccess: data => {
      dispatch(setApprovingContent(data.list));
    },
    onError: error => {
      console.log(error);
    }
  });
};
